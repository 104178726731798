


























import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {}
})
export default class CustomerEmailList extends Vue {
    get emailForm(): any {
        return this.$refs.emailForm;
    }

    search() {

    }

    add() {
        this.emailForm.add();
    }
}
